import React, { useState } from "react";
import { Link } from "react-router-dom";
import NavItems3 from "../NavItems3";
import Frame11332 from "../Frame11332";
import Frame114 from "../Frame114";
import Frame232 from "../Frame232";
import Frame222 from "../Frame222";
import jsPDF from "jspdf";
import "./Analysis.css";

function Analysis(props) {
  const [result, setResult] = useState(null);
  const {
    analysis,
    label,
    x1200PxLogo_Icam__20081,
    place,
    formalismesEnFonct,
    navItems3Props,
    frame11332Props,
    frame232Props,
    frame222Props,
    toggleProps,
  } = props;

  function inference() {
    fetch("https://api.scgreenoptimizer.fr/inferences", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Error performing inferences");
        }
        return response.json();
      })
      .then((data) => {
        setResult(data);
        alert("Successful Analysis");
      })
      .catch((error) => {
        console.error(error);
        // Handle error
      });
  }

  function saveAsPDF() {
    if (!result || !Array.isArray(result)) {
      alert("No results to save!");
      return;
    }

    const pdf = new jsPDF();

    let currentPage = 1;
    let currentVerticalPosition = 10; // Initial vertical position

    result.forEach((item, index) => {
      const documentIndex =
        item.DocumentIndex != null ? item.DocumentIndex + 1 : "Unknown";

      console.log(`Processing result ${index + 1}:`);
      console.log("Document Index:", documentIndex);
      console.log("Item:", item);

      let text = `Document ${documentIndex} Results\n\n`;

      if (item && item.Element) {
        text += `Analysis: ${item.Analysis}\nElement Info: Row ${item.Element.row}, Column ${item.Element.column}, Heading ${item.Element.heading}\n\n`;
      } else if (item && item.Analysis) {
        text += `${item.Analysis}\n\n`;
      } else {
        console.log("Unexpected item structure:", item);
      }

      const lines = pdf.splitTextToSize(
        text,
        pdf.internal.pageSize.getWidth() - 20
      );
      const lineHeight = pdf.getLineHeight();

      if (
        currentVerticalPosition + lineHeight * lines.length >
        pdf.internal.pageSize.getHeight() - 10
      ) {
        // Move to the next page if there's not enough space for the current result
        pdf.addPage();
        currentPage++;
        currentVerticalPosition = 10; // Reset the vertical position
      }

      lines.forEach((line, lineIndex) => {
        pdf.text(10, currentVerticalPosition + lineHeight * lineIndex, line);
      });

      currentVerticalPosition += lineHeight * lines.length;
    });

    pdf.save(`analysis_results_page_${currentPage}.pdf`);
  }

  return (
    <div className="container-center-horizontal">
      <div className="analysis-88 screen">
        <div className="overlap-group-13">
          {/* <div className="rectangle-4"></div> */}
          <div className="rectangle-2"></div>
          <NavItems3
            className={navItems3Props.className}
            frame1172Props={navItems3Props.frame1172Props}
            frame1162Props={navItems3Props.frame1162Props}
            frame1132Props={navItems3Props.frame1132Props}
          />
          <div className="nav-items-2-17">
            <Frame11332 className={frame11332Props.className} />
            <Frame114 />
            <Link to="/analysis">
              <div className="frame-115-115">
                <div className="analysis-89 valign-text-middle inter-medium-white-18px">
                  {analysis}
                </div>
              </div>
            </Link>
            <Frame232
              headerMenuDefault2Props={frame232Props.headerMenuDefault2Props}
            />
            <div className="label-98 inter-medium-star-dust-18-1px">
              {label}
            </div>
          </div>
          <Link to="/home">
            <img
              className="x1200px-logo_icam_-_2008-1-13"
              src={x1200PxLogo_Icam__20081}
              alt="1200px-Logo_ICAM_-_2008 1"
            />
          </Link>
          <Link to="/functional-view">
            <Frame222>{frame222Props.children}</Frame222>
          </Link>
          <Link to="/visual-management-1">
            <div className="frame-5-9">
              <div className="place-74 valign-text-middle inter-bold-tropical-rain-forest-18px">
                {place}
              </div>
            </div>
          </Link>
          <p className="formalismes-en-fonct valign-text-middle">
            {formalismesEnFonct}
            <button className="bu" onClick={inference}>
              Analysis
            </button>
            <button className="bu" onClick={saveAsPDF}>
              Save as PDF
            </button>
          </p>
          <div className="body">
            {result && (
              <div className="result-groups">
                {result
                  .reduce((acc, item) => {
                    const documentIndex = item.DocumentIndex;
                    const existingItem = acc.find(
                      (groupedItem) =>
                        groupedItem.documentIndex === documentIndex
                    );

                    if (existingItem) {
                      existingItem.results.push(item);
                    } else {
                      acc.push({
                        documentIndex,
                        results: [item],
                      });
                    }

                    return acc;
                  }, [])
                  .map((group, groupIndex) => (
                    <div key={groupIndex} className="result-box">
                      <h2>
                        Document {group.documentIndex + 1} {group.doc_name}{" "}
                        Results
                      </h2>
                      <br />
                      <div className="scrollable-container">
                        {group.results.map((item, index) => (
                          <div key={index} className="result-item">
                            {item.Element && (
                              <>
                                <p>Analysis: {item.Analysis}</p>
                                <p>
                                  Element Info: Row {item.Element.row}, Column{" "}
                                  {item.Element.column}, Heading{" "}
                                  {item.Element.heading}, Document{" "}
                                  {item.Element.doc}
                                </p>
                              </>
                            )}
                            {!item.Element && <p>{item.Analysis}</p>}
                          </div>
                        ))}
                      </div>
                    </div>
                  ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Analysis;
